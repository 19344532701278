<template>
    <div class="notificaton-user-profile-popup__wrapper">
        <div class="notificaton-user-profile-popup__inner">
            <div
                class="notificaton-user-profile-popup__close"
                @click="$emit('close')"
            >
                x
            </div>
            <div v-if="user" class="notificaton-user-profile-popup__content">
                <div class="notificaton-user-profile-popup__header">
                    <img :src="user?.photo1" alt="" />
                </div>
                <div class="notificaton-user-profile-popup__info">
                    <h1>
                        {{ questionnaire?.name }},
                        {{ setAge(questionnaire?.age) }}
                    </h1>
                    <h2>{{ questionnaire?.city }}</h2>
                    <div class="notificaton-user-profile-popup__description">
                        <p v-html="questionnaire?.profileDescription"></p>
                    </div>
                </div>
            </div>

            <div
                v-if="!user && !err"
                class="notificaton-user-profile-popup__content"
            >
                <Loader showLabel="true" label="wczytywanie profilu..." />
            </div>

            <div v-if="err" class="notificaton-user-profile-popup__err">
                <p>
                    Upss... nie udało się pobrać profilu usera, wystąpił błąd:
                </p>
                <div class="err">
                    {{ message }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { defineProps, ref, onMounted } from 'vue'
    import axios from 'axios'
    import Loader from '../atoms/Loader.vue'

    const user = ref(null)
    const questionnaire = ref(null)
    const err = ref(false)
    const message = ref(null)
    const API_URL = process.env.VUE_APP_API

    const props = defineProps({
        userID: {
            type: String,
            required: true
        }
    })

    const getCookie = name => {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        if (parts.length === 2) {
            return parts.pop().split(';').shift()
        }
    }

    const setAge = el => {
        if (!el) return ''

        const birthDate = new Date(el)
        if (isNaN(birthDate)) return ''

        const date = new Date()
        let age = date.getFullYear() - birthDate.getFullYear()

        const month = date.getMonth()
        const day = date.getDate()
        const birthMonth = birthDate.getMonth()
        const birthDay = birthDate.getDate()

        if (month < birthMonth || (month === birthMonth && day < birthDay)) {
            age--
        }

        return age
    }

    const getUser = async () => {
        // Bearer token included ✅
        const authToken = getCookie('authToken')
        const config = {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        }

        try {
            const response = await axios.get(
                `${API_URL}api/questionnaire/singleuser/${props.userID}`,
                config
            )
            user.value = response.data.user
            questionnaire.value = response.data.questionnaire
        } catch (e) {
            err.value = true
            message.value = e.message
        }
    }

    onMounted(() => {
        getUser()
    })
</script>

<style lang="scss" scoped>
    .notificaton-user-profile-popup {
        &__wrapper {
            position: fixed;
            left: 0;
            top: 0;
            width: 100dvw;
            height: 100dvh;
            background-color: rgba(255, 255, 255, 0.055);
            backdrop-filter: blur(3px);
            z-index: 200;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__inner {
            width: clamp(360px, 650px, 60vw);
            padding: 26px 36px;
            -webkit-box-shadow: -3px 4px 38px 2px rgba(226, 226, 231, 1);
            -moz-box-shadow: -3px 4px 38px 2px rgba(226, 226, 231, 1);
            box-shadow: -3px 4px 38px 2px rgba(226, 226, 231, 1);
            position: relative;
            background-color: #fff;
            border-radius: 6px;
        }

        &__close {
            position: absolute;
            top: 10px;
            right: 20px;
            font-size: 24px;
            cursor: pointer;
            color: #111;
            &:hover {
                color: #11111193;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-height: 280px;
        }

        &__info {
            text-align: center;
            color: #111;

            p {
                margin-top: 0px;
                padding-top: 0px;
            }
        }

        &__description {
            margin-top: 12px;
        }

        &__header {
            width: 160px;
            height: 160px;
            border-radius: 100%;
            overflow: hidden;
            object-fit: cover;
            aspect-ratio: 1;
            margin-bottom: 14px;

            img {
                width: 160px;
            }

            h1 {
                font-size: 2rem;
            }
            h2 {
                font-size: 1.2rem;
            }
        }

        &__err {
            font-size: 1.8rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-height: 280px;
        }
    }
</style>
