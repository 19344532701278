// Import necessary Firebase modules
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'

const VAPID_KEY = process.env.VUE_APP_PUBLIC_VAPID_KEY

// Firebase configuration object
const firebaseConfig = {
    apiKey: 'AIzaSyDLEHSKZC5nQBXdgbWtEbKEVLbfVFzcLm4',
    authDomain: 'biuro-randkowe.firebaseapp.com',
    projectId: 'biuro-randkowe',
    storageBucket: 'biuro-randkowe.appspot.com',
    messagingSenderId: '970879373858',
    appId: '1:970879373858:web:9d8a0d209acf19c387f07b'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Firebase Cloud Messaging
const messaging = getMessaging(app)

// Request permission for notifications and get the token
export const requestNotificationPermission = async () => {
    try {
        // Ask the user for permission using the browser's Notification API
        const permission = await Notification.requestPermission()

        if (permission === 'granted') {
            console.log('Notification permission granted.')

            // Get the FCM token
            const token = await getToken(messaging, {
                vapidKey: VAPID_KEY
            })
            console.log('FCM Token:', token)
            return token
        } else {
            console.error('Notification permission denied.')
        }
    } catch (error) {
        console.error('Error getting permission or token:', error)
    }
}

// Register the service worker
navigator.serviceWorker
    .register('/firebase-messaging-sw.js') // Ensure the correct path to your service worker
    .then(registration => {
        console.log('Service Worker registered successfully:', registration)

        // Set the use of service worker with messaging (this is not needed in v9, so you can remove messaging.useServiceWorker)
        // messaging.useServiceWorker(registration) -- REMOVE THIS LINE, as it’s not used in the modular SDK

        // Get FCM token
        getToken(messaging, {
            vapidKey: VAPID_KEY,
            serviceWorkerRegistration: registration
        })
            .then(currentToken => {
                if (currentToken) {
                    console.log('Token retrieved:', currentToken)
                } else {
                    console.log('No registration token available.')
                }
            })
            .catch(err => {
                console.log('An error occurred while retrieving token: ', err)
            })
    })
    .catch(err => {
        console.log('Service Worker registration failed: ', err)
    })

// Listen for messages when the app is in the foreground
onMessage(messaging, payload => {
    console.log('Message received in the foreground:', payload)
    // Display notification
    const notificationTitle = payload.notification.title
    const notificationOptions = {
        body: payload.notification.body,
        icon: payload.notification.icon
    }
    new Notification(notificationTitle, notificationOptions)
})

// Export messaging for use in other parts of the app
export { messaging }
