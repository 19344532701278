import { defineStore } from 'pinia'
import axios from 'axios'

export const useMatchStore = defineStore('match', {
    state: () => ({
        areAvailableMatches: false,
        match: null,
        messages: [],
        lastMessageDate: null
    }),
    actions: {
        getCookie(name) {
            const value = `; ${document.cookie}`
            const parts = value.split(`; ${name}=`)
            if (parts.length === 2) {
                return parts.pop().split(';').shift()
            }
        },
        async checkMatches() {
            const authToken = this.getCookie('authToken')
            const config = {
                headers: { Authorization: `Bearer ${authToken}` }
            }
            try {
                const response = await axios.get(
                    `${process.env.VUE_APP_API}api/match/${localStorage.getItem(
                        'userId'
                    )}`,
                    config
                )

                const matches = response.data
                const matchesArr = [matches]

                const hasActiveMatches = matchesArr.some(
                    match => match && match.isRejected == false
                )

                this.areAvailableMatches = hasActiveMatches
                this.match = response.data
            } catch (e) {
                console.log(e)
            }
        },

        async getMessages() {
            // Bearer token included ✅
            let userId = window.localStorage.getItem('userId')

            const authToken = this.getCookie('authToken')
            const config = {
                headers: { Authorization: `Bearer ${authToken}` }
            }

            try {
                axios
                    .get(
                        `${this.url}api/comments/${userId}/${this.state.match.secondUserId}?limit=${this.pageSize}`,
                        config
                    )
                    .then(resp => {
                        this.messages = resp?.data.reverse()
                        this.lastMessageDate = this.messages[0].date
                    })
                    .catch(err => {
                        console.error(err)
                    })
            } catch (e) {
                console.log(e)
            }
        }
    }
})
