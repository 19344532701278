<template>
  <div class="send-message">
    <textarea
      ref="textarea"
      id="send-message"
      class="send-message__textarea"
      @keydown="autosize"
      @keyup.enter="sendMessage"
      v-model="message"
      rows="1"
      placeholder="Wyślij wiadomość..."
    ></textarea>
    <div @click="sendMessage" class="send-message__icon">
      <svg
        class=""
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        viewBox="0 0 256 256"
        xml:space="preserve"
      >
        <defs></defs>
        <g
          style="
            stroke: none;
            stroke-width: 0;
            stroke-dasharray: none;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 10;
            fill: none;
            fill-rule: nonzero;
            opacity: 1;
          "
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
        >
          <path
            d="M 89.999 3.075 C 90 3.02 90 2.967 89.999 2.912 c -0.004 -0.134 -0.017 -0.266 -0.038 -0.398 c -0.007 -0.041 -0.009 -0.081 -0.018 -0.122 c -0.034 -0.165 -0.082 -0.327 -0.144 -0.484 c -0.018 -0.046 -0.041 -0.089 -0.061 -0.134 c -0.053 -0.119 -0.113 -0.234 -0.182 -0.346 C 89.528 1.382 89.5 1.336 89.469 1.29 c -0.102 -0.147 -0.212 -0.288 -0.341 -0.417 c -0.13 -0.13 -0.273 -0.241 -0.421 -0.344 c -0.042 -0.029 -0.085 -0.056 -0.129 -0.082 c -0.118 -0.073 -0.239 -0.136 -0.364 -0.191 c -0.039 -0.017 -0.076 -0.037 -0.116 -0.053 c -0.161 -0.063 -0.327 -0.113 -0.497 -0.147 c -0.031 -0.006 -0.063 -0.008 -0.094 -0.014 c -0.142 -0.024 -0.285 -0.038 -0.429 -0.041 C 87.03 0 86.983 0 86.936 0.001 c -0.141 0.003 -0.282 0.017 -0.423 0.041 c -0.035 0.006 -0.069 0.008 -0.104 0.015 c -0.154 0.031 -0.306 0.073 -0.456 0.129 L 1.946 31.709 c -1.124 0.422 -1.888 1.473 -1.943 2.673 c -0.054 1.199 0.612 2.316 1.693 2.838 l 34.455 16.628 l 16.627 34.455 C 53.281 89.344 54.334 90 55.481 90 c 0.046 0 0.091 -0.001 0.137 -0.003 c 1.199 -0.055 2.251 -0.819 2.673 -1.943 L 89.815 4.048 c 0.056 -0.149 0.097 -0.3 0.128 -0.453 c 0.008 -0.041 0.011 -0.081 0.017 -0.122 C 89.982 3.341 89.995 3.208 89.999 3.075 z M 75.086 10.672 L 37.785 47.973 L 10.619 34.864 L 75.086 10.672 z M 55.136 79.381 L 42.027 52.216 l 37.302 -37.302 L 55.136 79.381 z"
            style="
              stroke: none;
              stroke-width: 1;
              stroke-dasharray: none;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 10;
              fill: rgb(0, 0, 0);
              fill-rule: nonzero;
              opacity: 1;
            "
            transform=" matrix(1 0 0 1 0 0) "
            stroke-linecap="round"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted } from 'vue'
  import { defineEmits } from 'vue'

  const emit = defineEmits(['sendMessage'])

  const textarea = ref(null)
  const message = ref(null)

  const sendMessage = () => {
    if (message.value) {
      emit('sendMessage', message.value)
      message.value = ''
    }
  }

  const autosize = () => {
    textarea.value.style.cssText = 'height:auto; padding:0'
    textarea.value.style.cssText = '-moz-box-sizing:content-box'
    textarea.value.style.cssText =
      'height:' + textarea.value.scrollHeight + 'px'
  }

  const handleTextarea = () => {
    var textarea = document.querySelector('textarea')

    // textarea.addEventListener('keydown', this.autosize)
  }

  onMounted(handleTextarea)
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/variables.scss';
  @import '@/assets/scss/breakpoints.scss';
  @import '@/assets/scss/containers.scss';
  @import '@/assets/scss/global.scss';
  @import '@/assets/scss/Nunito.css';

  #send-message {
    padding: 14px 90px 14px 20px;
    @include breakpoint-min('large') {
      padding: 14px 140px 14px 36px;
    }
  }

  .send-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    margin: 0px 5px;
    &__textarea {
      font-weight: 400;
      font-family: 'Open Sans', sans-serif;
      overflow: hidden;
      min-height: 40px;
      width: 100%;
      font-size: 16px;
      resize: none;
      display: block;
      border-radius: 10px;
      border: none;
      outline: none;
      background-color: #e1dbd5;
      position: relative;
      line-height: 30px;

      @include breakpoint-min('v-large') {
        margin: 0px 50px;
      }

      &::placeholder {
        font-weight: 400;
        font-family: 'Open Sans', sans-serif;
      }
    }
    &__icon {
      position: absolute;
      right: -44px;
      top: 50%;
      transform: translateY(-50%);
      width: 30px;
    }
  }
</style>
