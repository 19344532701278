<template>
    <div class="user-match">
        <div v-show="false">
            <UserProfileNotificaton
                @show-profile-once="() => console.log('')"
                ref="usrProfile"
                :name="this.userFullData?.questionnaire?.name"
                :photo="this.userFullData?.user.photo1"
                :city="this.questionnaire?.city"
                :id="this.userFullData?.user._id"
            />
        </div>

        <PopUp
            title="Czy na pewno chcesz zrezygnować z dalszej rozmowy ?"
            content=" Klikając na TAK użytkownik otrzyma powiadomienie o Twojej rezygnacji z
        dalszej rozmowy. Operacji nie da się cofnąć a w przyszłości nie będziemy
        parowali Ciebie z tym użytkownikiem."
            :show="showPopup"
            @approve="
                () => {
                    rejectTemporaryMatch()
                    showPopup = false
                }
            "
            @decline="
                () => {
                    showPopup = false
                }
            "
        />
        <div class="user-match__wrapper" v-if="secondMatchProfile">
            <div class="user-match__left">
                <UserMatchProfile
                    :data="secondMatchProfile"
                    :removeImages="true"
                    :hasGallery="true"
                />
                <div class="user-match__more-informations">
                    <div
                        v-if="secondMatchProfile?.additionalInfo"
                        class="user-match__more-informations-about"
                    >
                        <p class="user-match__more-informations-about-title">
                            Więcej o mnie
                        </p>
                        <p class="user-match__more-informations-about-content">
                            {{ secondMatchProfile?.additionalInfo || '' }}
                        </p>
                    </div>
                    <div
                        class="my-profile__more-informations"
                        v-if="
                            secondMatchProfile?.questionnaires[0]
                                .profileDescription
                        "
                    >
                        <div class="my-profile__more-informations-about">
                            <p
                                class="my-profile__more-informations-about-title"
                            >
                                Więcej o mnie
                            </p>
                            <p
                                class="my-profile__more-informations-about-content"
                            >
                                {{
                                    secondMatchProfile?.questionnaires[0]
                                        .profileDescription
                                }}
                            </p>
                        </div>
                    </div>
                    <div class="user-match__more-informations-wrapper">
                        <div
                            v-if="
                                secondMatchProfile?.questionnaires[0]
                                    .completeSentences
                            "
                            v-for="(
                                sentence, index
                            ) in secondMatchProfile?.questionnaires[0].completeSentences.slice(
                                0,
                                6
                            )"
                            :key="index"
                            class="my-profile__more-informations-item"
                        >
                            <div
                                class="my-profile__more-informations-item-circle"
                            >
                                <svg
                                    id="Group_326"
                                    data-name="Group 326"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="20.534"
                                    height="19.599"
                                    viewBox="0 0 20.534 19.599"
                                >
                                    <defs>
                                        <clipPath id="clip-path">
                                            <rect
                                                id="Rectangle_219"
                                                data-name="Rectangle 219"
                                                width="20.534"
                                                height="19.599"
                                                fill="#fff"
                                            />
                                        </clipPath>
                                    </defs>
                                    <g
                                        id="Group_325"
                                        data-name="Group 325"
                                        transform="translate(0 0)"
                                        clip-path="url(#clip-path)"
                                    >
                                        <path
                                            id="Path_307"
                                            data-name="Path 307"
                                            d="M15.262,19.6a2.2,2.2,0,0,1-1.02-.252l-3.974-2.074L6.293,19.347a2.187,2.187,0,0,1-2.3-.165,2.153,2.153,0,0,1-.871-2.124l.759-4.393L.662,9.553A2.148,2.148,0,0,1,.109,7.327,2.17,2.17,0,0,1,1.875,5.849l4.443-.641,1.987-4a2.195,2.195,0,0,1,3.924,0l1.987,4,4.443.641a2.168,2.168,0,0,1,1.213,3.7l-3.215,3.111.759,4.393a2.152,2.152,0,0,1-.87,2.124,2.193,2.193,0,0,1-1.284.417m-4.995-3.565a.592.592,0,0,1,.273.067l4.247,2.217a1,1,0,0,0,1.07-.077.985.985,0,0,0,.4-.987L15.45,12.56a.58.58,0,0,1,.169-.515L19.054,8.72a.984.984,0,0,0,.257-1.034A1,1,0,0,0,18.491,7l-4.748-.685A.587.587,0,0,1,13.3,6L11.179,1.725a1.02,1.02,0,0,0-1.823,0L7.233,6a.587.587,0,0,1-.441.318L2.044,7a1,1,0,0,0-.82.687A.984.984,0,0,0,1.48,8.72l3.436,3.325a.579.579,0,0,1,.168.515l-.811,4.694a.987.987,0,0,0,.4.987,1.006,1.006,0,0,0,1.07.077L9.995,16.1a.593.593,0,0,1,.273-.067"
                                            transform="translate(0 0)"
                                            fill="#fff"
                                        />
                                    </g>
                                </svg>
                            </div>
                            <p>
                                <strong>
                                    {{ completeSentencesText[index] }}
                                </strong>
                                {{ sentence }}
                            </p>
                        </div>
                    </div>

                    <div
                        v-if="checkIfWaitingForOtherPerson()"
                        class="user-match__right-button mobile-delete"
                    >
                        <Loader v-if="pending" />
                        <!-- first user -->
                        <button
                            :class="{ 'pointer-none': firstUserAcceptDate }"
                            v-if="isCurrentUserFirstUser"
                            @click="acceptMatch"
                        >
                            {{
                                firstUserAcceptDate
                                    ? 'Zaakceptowano :)'
                                    : 'AKCEPTUJE'
                            }}
                        </button>
                        <!-- second user user -->
                        <button
                            :class="{ 'pointer-none': secondUserAcceptDate }"
                            v-if="!isCurrentUserFirstUser"
                            @click="acceptMatch"
                        >
                            {{
                                secondUserAcceptDate
                                    ? 'Zaakceptowano :)'
                                    : 'AKCEPTUJE'
                            }}
                        </button>
                        <button
                            v-if="
                                (temporarymatch.firstUserAccept === 0 ||
                                    temporarymatch.secondUserAccept === 0) &&
                                !this.matchStore.areAvailableMatches
                            "
                            class="user-match__reject-btn"
                            @click="() => (showPopup = true)"
                        >
                            REZYGNUJE
                        </button>
                    </div>

                    <div
                        class="user-match__reject-existing-match"
                        v-if="this.matchStore.areAvailableMatches"
                    >
                        <RejectMatchButton
                            @reject="
                                () => {
                                    this.showPopup = true
                                }
                            "
                        />
                    </div>
                </div>
            </div>
            <div class="user-match__right">
                <div class="user-match__right-image">
                    <picture v-if="notification">
                        <img src="../assets/images/bbbasia-chat.png" alt="" />
                    </picture>
                    <p>
                        {{ userFullData?.questionnaire?.name }}, warto, żebyście
                        się poznali!
                        <br />
                        Kliknij „akceptuj”, jeśli chcesz wyrazić zainteresowanie
                        lub „rezygnuj”, jeśli chcesz, żebyśmy szukali dalej.
                    </p>
                </div>
                <div class="user-match__connected" v-if="message">
                    <p>{{ message }}</p>
                </div>

                <div class="user-match__right-button desktop-delete">
                    <Loader v-if="pending" />
                    <!-- first user -->
                    <button
                        :class="{ 'pointer-none': firstUserAcceptDate }"
                        v-if="isCurrentUserFirstUser"
                        @click="acceptMatch"
                    >
                        {{
                            firstUserAcceptDate
                                ? 'Zaakceptowano :)'
                                : 'AKCEPTUJ '
                        }}
                    </button>
                    <!-- second user user -->
                    <button
                        :class="{ 'pointer-none': secondUserAcceptDate }"
                        v-if="!isCurrentUserFirstUser"
                        @click="acceptMatch"
                    >
                        {{
                            secondUserAcceptDate
                                ? 'Zaakceptowano :)'
                                : 'AKCEPTUJ '
                        }}
                    </button>
                    <button
                        v-if="
                            (temporarymatch.firstUserAccept === 0 ||
                                temporarymatch.secondUserAccept === 0) &&
                            !this.matchStore.areAvailableMatches
                        "
                        class="user-match__reject-btn"
                        @click="() => (showPopup = true)"
                    >
                        REZYGNUJ
                    </button>
                </div>
                <!-- tutaj jest profil matcha -->
            </div>
        </div>
        <div class="user-match__empty" v-if="!secondMatchProfile">
            <img
                loading="lazy"
                src="@/assets/images/heart.svg"
                alt="Szukamy pary dla Ciebie"
            />
            <p>Szukamy pary dla Ciebie</p>
        </div>
        <div v-if="rejectMatch" class="user-match__reject">
            <div class="user-match__reject-box">
                <h2>Czy napewno rezygnujesz z dopasowania?</h2>
                <div class="user-match__reject-input">
                    <h3>NAPISZ DLACZEGO:</h3>
                    <textarea
                        name="rejectReason"
                        id="rejectReason"
                        cols="30"
                        rows="10"
                        placeholder="Pasjonują mnie podróże i takie tam rzeczy. Lubię kawę, dobrą książkę, siedzieć w domu i nigdzie nie wychodzić."
                        v-model="rejectReason"
                    ></textarea>
                </div>
                <div class="user-match__reject-buttons">
                    <button @click="hideThis">POMYŁKA</button>
                    <button @click="hideThisResign">TAK, REZYGNUJĘ</button>
                </div>
            </div>
        </div>
        <div v-if="rejectMatchInfo" class="user-match__reject-info">
            <h2>Twoje dopasowanie zostało skasowane.</h2>
            <button @click="hideThisInfo">WRÓĆ</button>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import UserMatchProfile from '../components/atoms/UserMatchProfile.vue'
    import PopUp from '@/components/atoms/PopUp.vue'
    import Loader from '@/components/atoms/Loader.vue'
    import UserProfileNotificaton from '@/components/notifications/UserProfileNotification.vue'
    import RejectMatchButton from '@/components/atoms/match/RejectMatchButton.vue'

    import { mapStores } from 'pinia'
    import { useMatchStore } from '@/stores/match.js'

    export default {
        data() {
            return {
                images: [],
                user: [],
                userFullData: null,
                questionnaire: [],
                loveInfo: [],
                temporarymatch: null,
                notification: true,
                rejectReason: '',
                secondMatchProfile: null,
                isHidden: true,
                rejectMatch: false,
                rejectMatchInfo: false,
                showPopup: false,
                message: null,
                waitingForAcceptance: false,
                isCurrentUserFirstUser: true,
                currentUserId: window.localStorage.getItem('userId'),
                completeSentencesText: [
                    'Uwielbiam',
                    'Nie przepadam',
                    'Tracę poczucie czasu, gdy',
                    'Nie żal mi pieniędzy na',
                    'Jestem najbardziej dumna/dumny z',
                    'Nie wyobrażam sobie życia bez',
                    'Najbardziej lubię spędzać wakacje',
                    'Nie jestem w stanie zjeść',
                    'W moich przyjaciołach najbardziej cenię',
                    'Najlepsza rada usłyszana w życiu to',
                    'Potrawa, którą przyrządzam po mistrzowsku to',
                    'Nie potrafię sobie odmówić',
                    'Cecha, która sprawia, że od razu kogoś lubię to',
                    'W wolnym czasie',
                    'Nigdy nie żal mi czasu na',
                    'Coś, co szybko wyprowadza mnie z równowagi to',
                    'Najgorszy prezent otrzymany w życiu to',
                    'Lubię zapach',
                    'Marzy mi się',
                    'Podziwiam ludzi za'
                ],
                acceptBtn: 'Akceptuj',
                firstUserAcceptDate: null,
                secondUserAcceptDate: null,
                pending: false
            }
        },
        components: {
            UserMatchProfile,
            PopUp,
            Loader,
            UserProfileNotificaton,
            RejectMatchButton
        },
        mounted() {
            this.getUser()
            this.getQuestionnaire()
        },
        methods: {
            checkIfWaitingForOtherPerson() {
                const match = this.temporarymatch

                if (!match || match.rejected) {
                    this.message =
                        'Nie znaleziono meczu lub mecz został odrzucony.'
                    return false
                }

                const isCurrentUserMatch =
                    match.userId === this.currentUserId ||
                    match.secondUserId === this.currentUserId

                if (!isCurrentUserMatch) {
                    this.message =
                        'Nie znaleziono meczu dla aktualnego użytkownika.'
                    return false
                }

                this.match = match

                const isCurrentUserFirstUser =
                    match.userId === this.currentUserId
                this.isCurrentUserFirstUser = isCurrentUserFirstUser

                if (
                    match.firstUserAccept === 1 &&
                    match.secondUserAccept === 1
                ) {
                    this.message = 'Jesteście połączeni. Przejdź do rozmowy.'
                    return false
                } else if (
                    (isCurrentUserFirstUser &&
                        match.firstUserAccept === 1 &&
                        match.secondUserAccept === 0) ||
                    (!isCurrentUserFirstUser &&
                        match.secondUserAccept === 1 &&
                        match.firstUserAccept === 0)
                ) {
                    this.message = 'Czekamy na akceptację drugiej osoby.'
                    this.waitingForAcceptance = true
                    return true
                } else {
                    this.message = 'Oczekiwanie na twoją akceptację.'
                    this.waitingForAcceptance = true
                    return true
                }
            },
            hideThisResign() {
                this.rejectMatch = false
                this.rejectMatchInfo = true
            },
            async rejectTemporaryMatch() {
                // Bearer token included ✅
                this.pending = true
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                const usrProfileHTML = this.$refs.usrProfile.$el.outerHTML

                try {
                    // 1. reject temporary match between users
                    const secondID =
                        this.currentUserId === this.temporarymatch.userId
                            ? this.temporarymatch.secondUserId
                            : this.temporarymatch.userId

                    await axios.post(
                        `${this.url}api/temporarymatch/reject/${this.currentUserId}`,
                        { secondID },
                        config
                    )

                    // 2. edit match between users (if match exist) , set isRejected to true and rejectedBy + rejectedDate
                    this.rejectMatchIfExist()

                    // 3 send reject announcement to second user
                    const message = `Dopasowana do Ciebie osoba zrezygnowała z naszej propozycji i zdecydowała się kontynuować poszukiwania. Zapewniamy, że jak najszybciej postaramy się przedstawić Ci kolejne dopasowanie. O wszystkim poinformujemy Cię mailem i/lub sms-em.<br/><br/>Pozostajemy w kontakcie!<br/>Basia i Zespół Biura Randkowego`

                    await axios.post(
                        `${this.url}api/announcement/send`,
                        {
                            title: `Druga osoba zrezygnowała`,
                            message: message,
                            all: false,
                            toUserId: `${this.secondMatchProfile?.questionnaires?.[0]?.userId}`,
                            slotComponent: {
                                name: 'UserProfileNotification',
                                props: {
                                    name: `${this.questionnaire?.name}`,
                                    city: `${this.questionnaire?.city}`,
                                    id: `${this.questionnaire?.userId}`,
                                    photo: `${this.user?.photo1}`
                                }
                            }
                        },
                        config
                    )

                    this.rejectMatchInfo = true

                    window.setTimeout(() => {
                        // location.reload()
                        this.pending = false
                    }, 3000)
                } catch (e) {
                    this.pending = false
                    console.log(e)
                }
            },
            async rejectMatchIfExist() {
                // Bearer token included ✅

                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                try {
                    const response = await axios.put(
                        `${this.url}api/match/reject`,
                        {
                            firstuser: this.temporarymatch.userId,
                            seconduser: this.temporarymatch.secondUserId,
                            rejectedBy: this.currentUserId
                        },
                        config
                    )
                } catch (e) {
                    console.log(e)
                }
            },

            hideThis() {
                this.rejectMatch = false
            },
            hideThisInfo() {
                window.location.reload()
                this.rejectMatchInfo = false
            },
            getUser() {
                if (!localStorage.getItem('userId')) {
                    location.replace('/login')
                }
                axios
                    .get(
                        `${this.url}api/verify/${localStorage.getItem(
                            'userId'
                        )}`
                    )
                    .then(resp => {
                        this.user = resp.data.user
                        this.images.push(this.user.photo1)
                        this.images.push(this.user.photo2)
                        this.images.push(this.user.photo3)
                        this.images.push(this.user.photo4)

                        for (let i = 0; i < 4; i++) {
                            this.loveInfo.push({
                                text: '<strong>Uwielbiam</strong> Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut'
                            })
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })

                this.getUserFullData()
            },
            getUserFullData() {
                if (!localStorage.getItem('userId')) {
                    location.replace('/login')
                }

                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                axios
                    .get(
                        `${this.url}api/users/${localStorage.getItem(
                            'userId'
                        )}`,
                        config
                    )
                    .then(resp => {
                        this.userFullData = resp.data
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            getCookie(name) {
                const value = `; ${document.cookie}`
                const parts = value.split(`; ${name}=`)
                if (parts.length === 2) {
                    return parts.pop().split(';').shift()
                }
            },
            async getQuestionnaire() {
                let id = window.localStorage.getItem('userId')
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                try {
                    const response = await axios
                        .get(`${this.url}api/users/singleuser/${id}`, config)
                        .then(resp => {
                            // console.log(resp.data)
                            this.questionnaire = resp.data
                        })
                } catch (e) {
                    console.log(e)
                }
            },
            async getTemporaryMatch() {
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                let id = window.localStorage.getItem('userId')

                try {
                    const response = await axios
                        .get(`${this.url}api/temporarymatch/${id}`, config)
                        .then(resp => {
                            if (resp.data.length > 0) {
                                this.temporarymatch = resp.data?.[0]
                                this.firstUserAcceptDate = resp.data?.[0]
                                    .firstUserAcceptDate
                                    ? resp.data?.[0].firstUserAcceptDate
                                    : null || null
                                this.secondUserAcceptDate = resp.data?.[0]
                                    .secondUserAcceptDate
                                    ? resp.data?.[0].secondUserAcceptDate
                                    : null || null

                                this.getProfile(
                                    this.temporarymatch.secondUserId
                                )
                                this.getMatchProfile()
                                this.checkIfWaitingForOtherPerson()
                                if (
                                    this.temporarymatch.firstUserAccept == 1 &&
                                    this.temporarymatch.secondUserAccept == 1
                                ) {
                                    this.checkMatch()
                                }
                            }
                        })
                } catch (e) {
                    console.log(e)
                }
            },

            getProfile(id) {
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                axios
                    .get(`${this.url}api/users/${id}`, config)
                    .then(resp => {
                        this.profile = resp.data
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },

            async acceptMatch() {
                this.pending = true
                const timeout = ms =>
                    new Promise((_, reject) =>
                        setTimeout(
                            () => reject(new Error('Request timed out')),
                            ms
                        )
                    )

                try {
                    const authToken = this.getCookie('authToken')
                    const config = {
                        headers: { Authorization: `Bearer ${authToken}` }
                    }
                    const userId = window.localStorage.getItem('userId')
                    const secondUserId =
                        this.secondMatchProfile?.questionnaires?.[0]?.userId

                    const TIMEOUT_DURATION = 3000

                    // Accept the match
                    await Promise.race([
                        axios.post(
                            `${this.url}api/temporarymatch/check`,
                            {
                                id: this.temporarymatch._id,
                                userId: userId
                            },
                            config
                        ),
                        timeout(TIMEOUT_DURATION)
                    ])

                    // Add user to the accepted list
                    await Promise.race([
                        axios.post(
                            `${this.url}api/users/add-to-accepted-list`,
                            {
                                userId: userId,
                                acceptdListedUserId: secondUserId
                            },
                            config
                        ),
                        timeout(TIMEOUT_DURATION)
                    ])

                    // w przypadku akceptacji matcha aktualizuje tylko jednego usera tego, który akceptuje
                    // gdy user akceptuje to do jego accepted list trafia druga osoba

                    //add second user to accepted list
                    // await Promise.race([
                    //     axios.post(
                    //         `${this.url}api/users/add-to-accepted-list`,
                    //         {
                    //             userId: secondUserId,
                    //             acceptdListedUserId: userId
                    //         },
                    //         config
                    //     ),
                    //     timeout(TIMEOUT_DURATION)
                    // ])

                    setTimeout(() => {
                        this.pending = false
                        window.location.reload()
                    }, 3000)
                } catch (error) {
                    this.pending = false
                    console.error('Error:', error.message)
                }
            },
            checkMatch() {
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                let id = window.localStorage.getItem('userId')
                axios
                    .get(`${this.url}api/match/${id}`, config)
                    .then(resp => {
                        // this.$router.push('/panel/chat');
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            async getMatchProfile() {
                // Bearer token included ✅
                console.log('getMatchProfile')
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                try {
                    let id = this.temporarymatch.userId

                    if (
                        this.temporarymatch.userId ==
                        localStorage.getItem('userId')
                    ) {
                        id = this.temporarymatch.secondUserId
                    }

                    const response = await axios
                        .get(`${this.url}api/edit-user/` + id, config)
                        .then(response => {
                            // console.log(response.data)
                            this.secondMatchProfile = response.data
                            this.isHidden = false
                        })
                        .catch(error => {
                            console.log(error)
                        })
                } catch (e) {
                    console.log(e)
                }
            }
        },
        computed: {
            ...mapStores(useMatchStore)
        },
        created() {
            this.getTemporaryMatch()
        }
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/global.scss';
    @import '@/assets/scss/Nunito.css';

    .user-match {
        &__reject-existing-match {
            display: none;
            @include breakpoint-max('mobile') {
                display: flex;
                justify-content: center;
                margin-top: 30px;
            }
        }

        &__reject-btn {
            &:hover {
                background-color: #d13724 !important;
                color: #fff !important;
            }
        }
        &__connected {
            color: $pink;
            text-align: center;
            font-weight: 700;
            border-radius: 30px 0px 30px 30px;
            background-color: #e1dbd5;
            padding: 32px 35px;
            margin-top: -30px;
            @include breakpoint-max('large') {
                margin-right: 108px;
                margin-top: -70px;
            }
            @include breakpoint-max('mobile') {
                margin-right: 70px;
                margin-top: -70px;
            }
        }
        padding-right: 185px;
        padding-bottom: 120px;
        @include breakpoint-max('mobile') {
            padding-bottom: 80px;
        }
        @include breakpoint-max('v-large') {
            padding-right: 30px;
        }
        .desktop-delete {
            @include breakpoint-max('large') {
                display: none;
            }
        }
        .mobile-delete {
            display: none;
            @include breakpoint-max('large') {
                display: flex;
                margin-top: 150px;
            }
            @include breakpoint-max('mobile') {
                margin-top: 129px;
            }
        }
        &__empty {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            font-size: 4.5rem;
            line-height: 5rem;
            font-weight: 400;
            font-family: 'Promenade', serif;
            @include breakpoint-max('xl-tablet') {
                padding-left: 0;
                text-align: center;
            }

            img {
                max-width: 400px;
            }
        }
        &__wrapper {
            display: flex;
            column-gap: 41px;
            justify-content: space-between;
            @include breakpoint-max('large') {
                flex-direction: column-reverse;
                row-gap: 80px;
            }
            @include breakpoint-max('mobile') {
                row-gap: 30px;
            }
        }
        &__left {
            flex: 82;
        }
        &__more-informations {
            margin-top: 47px;
            width: 100%;
            max-width: 823px;
            @include breakpoint-max('large') {
                max-width: 8230px;
            }
            @include breakpoint-max('xs-tablet') {
                padding-left: 30px;
            }
        }
        &__more-informations-about {
            width: 100%;
            margin-bottom: 49px;
            @include breakpoint-max('mobile') {
                margin-bottom: 39px;
            }
        }
        &__more-informations-about-title {
            padding: 7px 0;
            text-align: center;
            background-color: $pink;
            color: $white;
            font-size: 1.6rem;
            line-height: 2.4rem;
            font-weight: 700;
            font-family: 'Open Sans', sans-serif;
        }
        &__more-informations-about-content {
            padding: 35px;
            border: 1px solid $black;
            font-size: 1.6rem;
            line-height: 2.2rem;
            font-weight: 400;
            font-family: 'Open Sans', sans-serif;
            @include breakpoint-max('mobile') {
                padding: 25px 16px;
            }
        }
        &__more-informations-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 65px 27px;
            @include breakpoint-max('mobile') {
                grid-template-columns: repeat(1, 1fr);
                row-gap: 44px;
            }
        }
        &__more-informations-item {
            display: flex;
            column-gap: 15px;
            border-top: 1px solid $black;
            padding-top: 19px;
            svg {
                min-width: 21px;
                path {
                    fill: $white;
                }
            }
            p {
                font-size: 1.6rem;
                line-height: 3rem;
                font-family: 'Open Sans', sans-serif;
                font-weight: 400;
                strong {
                    font-weight: 700;
                }
            }
            @include breakpoint-max('mobile') {
                padding-top: 15px;
            }
        }
        &__more-informations-item-circle {
            display: grid;
            place-items: center;
            width: 42px;
            height: 42px;
            min-width: 42px;
            min-height: 42px;
            background-color: $pink;
            border-radius: 50%;
        }
        &__right {
            flex: 39;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            row-gap: 38px;
            @include breakpoint-max('large') {
                row-gap: 80px;
            }
            @include breakpoint-max('xs-tablet') {
                padding-left: 30px;
            }
        }
        &__right-image {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            row-gap: 15px;
            picture {
                display: block;
                width: fit-content;
                img {
                    display: block;
                    width: 96px;
                    object-fit: cover;
                    aspect-ratio: 1;
                    border-radius: 50%;
                    @include breakpoint-max('mobile') {
                        width: 57px;
                    }
                }
            }
            p {
                padding: 32px 35px;
                border-radius: 30px 0px 30px 30px;
                font-size: 1.4rem;
                line-height: 1.6rem;
                font-weight: 400;
                font-family: 'Open Sans', sans-serif;
                background-color: #e1dbd5;
                @include breakpoint-max('large') {
                    border-radius: 30px 30px 0px 30px;
                }
                @include breakpoint-max('mobile') {
                    padding: 21px;
                    max-width: 273px;
                    font-size: 1.2rem;
                    line-height: 1.6rem;
                }
            }
            @include breakpoint-max('large') {
                flex-direction: row-reverse;
                column-gap: 13px;
            }
        }
        &__right-button {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 11px;
            button {
                border: none;
                outline: none;
                border: 1px solid $black;
                background-color: #e1dbd5;
                padding: 15px 0;
                width: 200px;
                font-size: 1.6rem;
                line-height: 2.4rem;
                font-weight: 700;
                font-family: 'Open Sans', sans-serif;
                cursor: pointer;
                &:first-child {
                    background-color: $pink;
                    color: $white;
                    border-color: $pink;
                }
            }
            @include breakpoint-max('mobile') {
                margin-top: 129px;
            }
        }
        .accepted {
            button {
                &:first-child {
                    background-color: #e1dbd5;
                    color: $black;
                    border-color: $black;
                }
            }
        }
        // #endregion
        &__reject-info {
            padding: 0 30px;
            padding-top: 150px;
            position: fixed;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background-color: #f4f2ee;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            h2 {
                text-align: center;
                margin-bottom: 78px;
                font-size: 4.5rem;
                line-height: 5rem;
                font-weight: 400;
                font-family: 'Promenade', serif;
                @include breakpoint-max('tablet') {
                    margin-bottom: 40px;
                }
                @include breakpoint-max('mobile') {
                    font-size: 3.5rem;
                    line-height: 4rem;
                }
            }
            button {
                width: 200px;
                padding: 15px 0;
                background-color: #d13724;
                cursor: pointer;
                font-size: 1.6rem;
                line-height: 3rem;
                font-weight: 700;
                font-family: 'Open Sans', sans-serif;
                color: $white;
                @include breakpoint-max('mobile') {
                    padding: 10px 0;
                    width: 180px;
                }
            }
        }
        &__reject {
            padding: 0 30px;
            padding-top: 150px;
            position: fixed;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background-color: #f4f2ee;
            display: grid;
            place-items: center;
            &-btn {
                color: #000;
                appearance: none;
            }
            @include breakpoint-max('mobile') {
                padding-top: 120px;
            }
        }
        &__reject-box {
            width: 100%;
            max-width: 700px;
            display: flex;
            flex-direction: column;
            align-items: center;
            h2 {
                text-align: center;
                margin-bottom: 78px;
                font-size: 4.5rem;
                line-height: 5rem;
                font-weight: 400;
                font-family: 'Promenade', serif;
                @include breakpoint-max('tablet') {
                    margin-bottom: 40px;
                }
                @include breakpoint-max('mobile') {
                    font-size: 3.5rem;
                    line-height: 4rem;
                }
            }
        }
        &__reject-input {
            width: 100%;
            max-width: 600px;
            display: flex;
            flex-direction: column;
            align-items: center;
            border: 1px solid $black;
            margin-bottom: 40px;
            h3 {
                border-bottom: 1px solid $black;
                width: 100%;
                text-align: center;
                background-color: #e1dbd5;
                color: $black;
                font-family: 'Open Sans', sans-serif;
                padding: 15px 0;
            }
            textarea {
                width: 100%;
                height: 260px;
                padding: 36px 58px;
                outline: none;
                border: none;
                font-size: 1.6rem;
                line-height: 2.2rem;
                font-weight: 400;
                font-family: 'Open Sans', sans-serif;
                resize: none;
                @include breakpoint-max('tablet') {
                    height: 180px;
                }
                @include breakpoint-max('mobile') {
                    padding: 15px;
                    height: 140px;
                }
            }
            @include breakpoint-max('tablet') {
                margin-bottom: 20px;
            }
        }
        &__reject-buttons {
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 15px;
            button {
                width: 200px;
                padding: 15px 0;
                border: 1px solid $black;
                background-color: #e1dbd5;
                cursor: pointer;
                font-size: 1.6rem;
                line-height: 3rem;
                font-weight: 700;
                font-family: 'Open Sans', sans-serif;
                &:first-child {
                    color: $white;
                    background-color: $pink;
                    border-color: $pink;
                }
                @include breakpoint-max('mobile') {
                    padding: 10px 0;
                    width: 180px;
                }
            }
        }
    }
</style>
